<template>
    <div>
        <!-- Bouton pour ouvrir le chat -->
        <b-button
            id="chatToggle"
            @click="showChat = !showChat"
            variant="primary"
            pill
            v-b-tooltip.hover.top="showChat ? 'Masquer le chat' : 'Ouvrir le chat'"
        >
            <b-icon icon="chat" style="width: 100%; height: auto"></b-icon>
            <b-badge v-if="totalNotifications > 0" variant="danger" style="position: absolute; top: -10px; left: -5px">{{
                totalNotifications
            }}</b-badge>
        </b-button>
        <!-- Volet messagerie -->
        <div id="chat" class="card card-custom d-flex h-100" :class="{ 'chat-open': showChat }">
            <div id="closeChat" @click="showChat = false" v-if="showChat">
                <i class="fas fa-chevron-right"></i>
            </div>
            <!-- Header -->
            <div id="header" class="d-flex w-100 mb-5 px-5 pt-5" style="height: 50px">
                <div>
                    <b-button
                        v-if="!allChat"
                        @click="allChat ? (showChat = !showChat) : (allChat = true)"
                        variant="outline-danger"
                        pill
                        style="width: 1.3rem, height: 1.3rem;"
                        class="d-flex align-items-center justify-content-center customBtn"
                    >
                        <b-icon icon="arrow-left" style="width: 1rem; height: 1rem" @click="getMessages()"></b-icon>
                    </b-button>
                </div>
                <div class="d-flex align-items-center" :class="allChat ? '' : 'ml-3'">
                    <p class="m-0 h3">{{ allChat || loadingConversations ? 'Messagerie' : chatName }}</p>
                </div>
            </div>
            <div v-if="loadingConversations" class="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
                <b-spinner variant="primary" />
                <p class="mt-2">Veuillez patienter...</p>
            </div>
            <!-- Liste des conversations -->
            <b-list-group v-else-if="allChat">
                <b-list-group-item
                    v-for="(conversation, index) in conversations"
                    :key="index"
                    @click="getUserMessage(conversation.user ? conversation.user.id : 0)"
                    button
                >
                    <div v-if="conversation" class="d-flex justify-content-between align-items-center">
                        <img
                            width="35"
                            height="35"
                            class="mr-3"
                            :src="conversation.user ? conversation.user.avatar : 'https://api.dicebear.com/6.x/initials/svg?seed=général'"
                            alt="avatar"
                        />
                        <div style="width: 95%" class="d-flex flex-column justify-content-center">
                            <p v-if="conversation.user" class="font-weight-bold m-0">
                                {{ conversation.user.firstname }} {{ conversation.user.lastname }}
                            </p>
                            <p v-else class="font-weight-bold m-0">Général</p>
                            <div class="d-flex" v-if="conversation.lastMessage">
                                <p class="m-0">{{ truncateMessage(conversation.lastMessage.message) }}</p>
                                <p class="m-0 ml-2 text-muted" v-if="conversation.lastMessage.created_at">
                                    {{ getDate(conversation.lastMessage.created_at) }}
                                </p>
                            </div>
                        </div>
                        <b-badge v-if="conversation.notification > 0" variant="danger">{{ conversation.notification }}</b-badge>
                    </div>
                </b-list-group-item>
            </b-list-group>
            <div v-else id="messageList" class="d-flex flex-column" :style="heightCalcul()">
                <!-- Affichage des messages -->
                <div v-if="userConversations.length > 0" id="messageContainer" style="overflow: scroll" ref="messageContainerRef">
                    <Message
                        v-for="(message, index) in userConversations"
                        :key="index"
                        :messageObject="message"
                        :name="chatName"
                        :tuteur="tuteur"
                        :id="index === userConversations.length - 1 ? 'scrollToMe' : ''"
                        @answerMessage="answerMessage"
                    />
                </div>
                <div v-else class="w-100 h-100 d-flex justify-content-center align-items-center">
                    <p>Aucun message</p>
                </div>
            </div>
            <!-- Zone de saisie d'un nouveu message -->
            <div v-if="!allChat && !loadingConversations" class="d-flex flex-column px-3 py-2">
                <div v-if="answerMessageObj" class="d-flex justify-content-between w-100">
                    <div class="pr-3">
                        <p class="mb-1">Transférer dans le chat général</p>
                        <p class="mb-1">«{{ truncateMessage(answerMessageObj.message) }}»</p>
                    </div>
                    <div class="d-flex justify-content-center align-items-center">
                        <b-button
                            variant="danger"
                            pill
                            id="closeAnswerMessage"
                            class="d-flex align-items-center justify-content-center"
                            @click="answerMessageObj = ''"
                        >
                            <b-icon icon="x"></b-icon>
                        </b-button>
                    </div>
                </div>
                <div v-if="chatName !== 'Général' || tuteur" class="d-flex align-items-center">
                    <b-form-textarea
                        id="message"
                        v-model="newMessage"
                        type="text"
                        placeholder="Votre message..."
                        class="flex-grow-1 mr-3"
                        style="resize: none"
                        rows="1"
                        max-rows="3"
                    ></b-form-textarea>
                    <b-button
                        v-b-tooltip.hover.top="'Envoyer le message'"
                        variant="primary"
                        :disabled="newMessage.trim() === '' || sendingLoading"
                        @click="sendMessage()"
                        class="d-flex align-items-center justify-content-center align-self-end"
                        style="width: 50px; height: 40px"
                    >
                        <b-icon v-if="!sendingLoading" icon="arrow-right" />
                        <b-spinner v-else small variant="light" />
                    </b-button>
                </div>
                <div v-else class="h-100 w-100 d-flex justify-content-center align-items-center">
                    <p>Seul le tuteur peut écrire dans cette discussion.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GET_MESSAGES, SEND_MESSAGE } from '@/core/services/store/api/chat.service';

export default {
    name: 'Chat',
    props: {
        sessionId: {
            type: Number,
            required: true
        },
        tuteur: {
            type: Boolean,
            required: false
        }
    },
    components: {
        Message: () => import('@/view/content/components/Message.vue')
    },
    data() {
        return {
            answerMessageObj: '',
            newMessage: '',
            allChat: true,
            chatName: '',
            showChat: false,
            loadingConversations: true,
            totalNotifications: 0,
            conversations: {},
            userConversations: {},
            intervalMessage: null,
            intervalUserMessage: null,
            receiverId: null,
            sendingLoading: false
        };
    },
    mounted() {
        if (this.allChat) {
            this.getMessages();
            this.intervalMessage = setInterval(() => {
                if (this.allChat) {
                    this.getMessages();
                }
            }, process.env.VUE_APP_CHAT_REFRESH * 1000);
        }
    },
    methods: {
        heightCalcul() {
            if (this.answerMessageObj) {
                //if mobile
                if (window.innerWidth <= 768) {
                    return 'height: calc(100% - 245px);';
                } else {
                    return 'height: calc(100% - 190px);';
                }
            } else {
                if (window.innerWidth <= 768) {
                    return 'height: calc(100% - 195px);';
                } else {
                    return 'height: calc(100% - 140px);';
                }
            }
        },
        answerMessage(messageObject) {
            this.answerMessageObj = messageObject;
            this.getUserMessage(0);
            this.heightCalcul();
        },
        sendMessage() {
            if (this.newMessage.trim() === '') {
                return;
            }
            this.sendingLoading = true;
            this.$store
                .dispatch(SEND_MESSAGE, {
                    id: this.sessionId,
                    receiver_id: this.receiverId === 0 ? null : this.answerMessageObj ? null : this.receiverId,
                    response_to: this.answerMessageObj ? this.answerMessageObj.id : null,
                    message: this.newMessage
                })
                .then(() => {
                    this.scrollToElement();
                    this.getUserMessage(this.answerMessageObj ? 0 : this.receiverId, false);
                    this.sendingLoading = false;
                    this.newMessage = '';
                    this.answerMessageObj = '';
                })
                .catch(() => {
                    this.sendingLoading = false;
                });
        },
        //Permet de scroller jusqu'au dernier message
        scrollToElement() {
            this.$nextTick(() => {
                if (!this.$refs.messageContainerRef) {
                    return;
                }
                this.$refs.messageContainerRef.scrollTop = this.$refs.messageContainerRef.scrollHeight;

                // Utilisation de requestAnimationFrame pour assurer que le défilement se produit après la mise à jour du DOM
                requestAnimationFrame(() => {
                    const scrollToMe = this.$refs.messageContainerRef.querySelector('#scrollToMe');
                    if (scrollToMe) {
                        scrollToMe.scrollIntoView();
                    }
                });
            });
        },
        getUserMessage(userId, loading = true, scroll = true) {
            if (loading) {
                this.loadingConversations = true;
            }
            this.receiverId = userId;
            this.allChat = false;
            this.$store
                .dispatch(GET_MESSAGES, { id: this.sessionId, userId: userId })
                .then((response) => {
                    this.userConversations = response.data;
                    //chercher le nom de la personne avec qui on discute dans this.conversations grâce au userId
                    for (const key in this.conversations) {
                        if (this.conversations[key].user && this.conversations[key].user.id === userId) {
                            this.chatName = this.conversations[key].user.firstname + ' ' + this.conversations[key].user.lastname;
                            break;
                        } else {
                            this.chatName = 'Général';
                        }
                    }
                    this.loadingConversations = false;
                })
                .finally(() => {
                    if (scroll) {
                        this.$nextTick(() => {
                            this.scrollToElement();
                        });
                    }
                });
        },
        getMessages() {
            this.chatName = '';
            this.answerMessageObj = '';
            this.totalNotifications = 0;
            this.$store.dispatch(GET_MESSAGES, { id: this.sessionId, userId: 'all' }).then((response) => {
                this.conversations = response.data;
                this.loadingConversations = false;

                for (const key in this.conversations) {
                    this.totalNotifications += this.conversations[key].notification;
                }
            });
        },
        truncateMessage(message) {
            //retourne le message en ne gardant que les 45 premiers caractères
            return message.length > 45 ? message.substring(0, 45) + '...' : message;
        },
        getDate(MessageDate) {
            //si MessageDate est aujourd'hui, on affiche l'heure sans les secondes
            //si MessageDate est hier, on affiche "hier"
            //si MessageDate est dans la semaine, on affiche le jour de la semaine abrégé avec une majuscule
            //sinon on affiche la date
            const date = new Date(MessageDate);
            const today = new Date();
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            const lastWeek = new Date();
            lastWeek.setDate(lastWeek.getDate() - 7);
            if (date.toDateString() === today.toDateString()) {
                return date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
            } else if (date.toDateString() === yesterday.toDateString()) {
                return 'Hier';
            } else if (date > lastWeek) {
                let formatDate = date.toLocaleDateString('fr-FR', { weekday: 'short' });
                return formatDate.charAt(0).toUpperCase() + formatDate.slice(1);
            } else {
                return date.toLocaleDateString('fr-FR');
            }
        }
    },
    watch: {
        chatName() {
            //éxecuter tout les 15 minutes la fonction getUserMessage
            if (!this.allChat && this.receiverId) {
                this.intervalUserMessage = setInterval(() => {
                    if (!this.allChat) {
                        this.getUserMessage(this.receiverId, false, false);
                    }
                }, process.env.VUE_APP_CHAT_REFRESH * 1000);
            } else {
                clearInterval(this.intervalUserMessage);
            }
        }
    },
    beforeDestroy() {
        clearInterval(this.intervalMessage);
        clearInterval(this.intervalUserMessage);
    }
};
</script>

<style>
#closeChat {
    position: absolute;
    top: 35px;
    right: 100%;
    background-color: rgb(55, 56, 56);
    border-radius: 8px 0px 0px 8px;
    width: 34px;
    height: 34px;
    z-index: -1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
#chat {
    position: fixed;
    top: 0;
    right: -25%;
    z-index: 96;
    width: 25%;
    height: 100%;
    background-color: #f3f3f3;
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.3);
    /* overflow: hidden; */
    transition: all 0.3s ease;
}
/* Si mobile, j'adapte la taille du volet messagerie */
@media (max-width: 768px) {
    #chat {
        right: -100%;
        width: 100%;
        top: 55px;
    }
}

.chat-open {
    right: 0 !important;
}

#chatToggle {
    position: fixed;
    top: 13%;
    right: 10px;
    z-index: 96;
    width: 50px;
    height: 50px;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.list-group {
    border-radius: 0 !important;
}

.btn.btn-outline-danger.customBtn {
    border-width: 2px;
}
#closeAnswerMessage {
    padding: 0 !important;
}
</style>
